import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useNotification } from "../common/Notification";
import { post, put } from "../common/axios";
import ReactModal from "react-modal";
import axios from "axios";
import { MdFileUpload } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";

ReactModal.setAppElement("#root");
interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  phoneNumber:string;
}
interface DataType {
  adminId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  status: string;
  phoneNumber:string;
  profileImageName: string;
  profileImageUrl: string;
}
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^[A-Za-z\s]+$/,
      "Only alphabets and spaces are allowed for this field "
    )
    .required("Required"),
    lastName: Yup.string()
    .matches(
      /^[A-Za-z\s]+$/,
      "Only alphabets and spaces are allowed for this field "
    )
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phoneNumber:Yup.string().required("Required"),
});

interface ClientManagementProps {
  modalIsOpen?: boolean;
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedClient: DataType | null;
}

const AdminManagement: React.FC<ClientManagementProps> = ({
  modalIsOpen = false,
  setModalIsOpen,
  selectedClient,
}) => {
  const { handleNotifications } = useNotification();
  const [uploadedImage, setUploadedImage]: any = useState(null);
  const [imageName, setImageName]: any = useState(null);
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    firstName: "",
    lastName: "",
    email: "",
    status: "Active",
    phoneNumber:""
  });

  const handleSubmit = (values: any) => {
    console.log('values', values);  
    if (selectedClient) {
      put(`/admin/admin-update/${selectedClient.adminId}`, {
        ...values,
        profileImage: imageName,
      }).then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.settings && data.settings.success) {
            handleNotifications("success", data.settings.message);
            if (setModalIsOpen) {
              setModalIsOpen(false);
            }
          } else {
            handleNotifications("error", data.settings.message);
          }
        }
      });
    } else {
      console.log({ ...values, profileImage: imageName });
      post("/admin/admin-add", { ...values, profileImage: imageName }).then(
        (response) => {
          if (response) {
            const { data } = response;
            if (data && data.settings && data.settings.success) {
              handleNotifications("success", data.settings.message);
              if (setModalIsOpen) {
                setModalIsOpen(false);
              }
            } else {
              handleNotifications("error", data.settings.message);
            }
          }
        }
      );
    }
  };

  const handleFileUpload = async (event: any) => {
    const file = event.currentTarget.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/upload_file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (
        response &&
        response.data &&
        response.data.settings &&
        response.data.settings.success
      ) {
        const fileData = response.data.data;
        setImageName(fileData.name);
        setUploadedImage(fileData.url);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    if (selectedClient) {
      setInitialFormValues({
        firstName: selectedClient.firstName,
        lastName: selectedClient.lastName,
        email: selectedClient.email,
        status: selectedClient.status,
        phoneNumber:selectedClient.phoneNumber,
      });
      setImageName(selectedClient.profileImageName);
      setUploadedImage(selectedClient.profileImageUrl);
    }
  }, [selectedClient]);

  const checkEmailExist = async (email: any) => {
    const data: { email: any; adminId?: string } = {
      email,
    };
    if (selectedClient && selectedClient.adminId) {
      data.adminId = selectedClient.adminId;
    }

    const response = await post("/admin/admin_email_exist", data);
    return response && response.data && response.data.settings
      ? response.data.settings.success
      : false;
  };

  return (
    <ReactModal isOpen={modalIsOpen} ariaHideApp={false}>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnChange={true}
      >
        {({ errors, touched }) => (
          <Form className="max-w-lg mx-auto w-full">
            <div className="mb-5">
              <label
                htmlFor="firstName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
               First Name
              </label>
              <Field
                name="firstName"
                placeholder="First Name"
                className={`bg-gray-50 border ${
                  errors.firstName && touched.firstName
                    ? "border-red-500"
                    : "border-gray-300"
                } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              />
              {errors.firstName && touched.firstName ? (
                <div className="text-red-500">{errors.firstName}</div>
              ) : null}
            </div>

            <div className="mb-5">
              <label
                htmlFor="lastName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
               Last Name
              </label>
              <Field
                name="lastName"
                placeholder="Last Name"
                className={`bg-gray-50 border ${
                  errors.lastName && touched.lastName
                    ? "border-red-500"
                    : "border-gray-300"
                } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              />
              {errors.lastName && touched.lastName ? (
                <div className="text-red-500">{errors.lastName}</div>
              ) : null}
            </div>

            <div className="mb-5">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Email
              </label>
              <Field
                name="email"
                placeholder="Email"
                className={`bg-gray-50 border ${
                  errors.email && touched.email
                    ? "border-red-500"
                    : "border-gray-300"
                } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                validate={async (value: any) => {
                  if (!value) {
                    return "Required";
                  }
                  const emailExists = await checkEmailExist(value);
                  if (emailExists) {
                    return "Email already exists";
                  }
                }}
              />
              {errors.email && touched.email ? (
                <div className="text-red-500">{errors.email}</div>
              ) : null}
            </div>

            <div className="mb-5">
              <label
                htmlFor="phoneNumber"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Phone Number
              </label>
              <Field
                name="phoneNumber"
                placeholder="Phone Number"
                className={`bg-gray-50 border ${
                  errors.phoneNumber && touched.phoneNumber
                    ? "border-red-500"
                    : "border-gray-300"
                } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              />
              {errors.phoneNumber && touched.phoneNumber ? (
                <div className="text-red-500">{errors.phoneNumber}</div>
              ) : null}
            </div>

            <div className="mb-5">
              <div className="relative flex items-center">
                <Field
                  type="file"
                  id="fileUpload"
                  name="fileUpload"
                  onChange={(event: any) => {
                    handleFileUpload(event);
                  }}
                  className="sr-only"
                />
                <span
                  onClick={() => {
                    const fileUploadInput =
                      document.getElementById("fileUpload");
                    if (fileUploadInput) {
                      fileUploadInput.click(); // Simulate click on file input
                    }
                  }}
                  className="cursor-pointer bg-gray-50  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <MdFileUpload
                    className="mr-2"
                    style={{
                      fontSize: "24px",
                      width: "32px",
                      height: "32px",
                    }}
                  />
                </span>
                {uploadedImage && (
                  <div className="ml-3">
                    {uploadedImage.endsWith(".pdf") ? (
                      <div>
                        <a
                          href={uploadedImage}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <a
                            href={uploadedImage}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaFilePdf size={32} />
                          </a>
                        </a>
                      </div>
                    ) : (
                      <div>
                        <a
                          href={uploadedImage}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={uploadedImage}
                            alt="Uploaded"
                            style={{ height: "50px" }}
                          />
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="flex">
              <div className="flex items-center me-4">
                <Field
                  type="radio"
                  id="inline-radio"
                  name="status"
                  value="Active"
                  className="outline-none w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="status"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Active
                </label>
              </div>
              <div className="flex items-center me-4">
                <Field
                  type="radio"
                  id="inline-2-radio"
                  name="status"
                  value="Inactive"
                  className="outline-none w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="status"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Inactive
                </label>
              </div>
            </div>
            <div className="mt-6 ml-2 flex">
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: "#1C5257" }}
              >
                Submit
              </Button>
              {setModalIsOpen && (
                <Button
                  type="primary"
                  onClick={() => setModalIsOpen(false)}
                  className="ml-2"
                  style={{ background: "#1C5257" }}
                >
                  Close
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </ReactModal>
  );
};

export default AdminManagement;
