import axios from 'axios';
import events from './history'; // replace './history' with the actual path to the history.js file


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
console.log(process.env.BASE_URL)
export const get = (url: string, params?: object) => axiosInstance.get(url, { params });
export const post = (url: string, data: object) => axiosInstance.post(url, data);
export const put = (url: string, data: object) => axiosInstance.put(url, data);
export const patch = (url: string, data: object) => axiosInstance.patch(url, data);
export const remove = (url: string, data: object) => {
  return axiosInstance.request({
    method: 'delete',
    url: url,
    data: data
  });
};

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // **add your token here before any request here
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log("error :: ", error.response);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // if server respond in 500 status code
    console.log("error :: ", error);
    if (error.response.status === 500) {
      throw new Error("Internal server error !!");
    }

    // if server respond in 400 status code
    if (error.response.status === 400) {
      throw new Error(error.response.data.message);
    }
    console.log('error---->', error, error.response.data.settings.success === -1)
    // eslint-disable-next-line eqeqeq
    if (error.response.status === 401) {
      localStorage.removeItem("token"); // clear the token
      events.emit('unauthorized');
      // Don't return or throw anything
    } else {
      // For all other errors, reject the promise
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;