import {
  Button,
  Layout,
  Menu,
  // Typography,
  theme,
  Dropdown,
  Space,
  Avatar,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import React, { useState } from "react";
import {
  AiOutlineLogout,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineDown,
  AiOutlineKey,
  AiFillHome,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const CustomLayout = ({ children }: any) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
let userDetail:any= localStorage.getItem('userData');
userDetail = JSON.parse(userDetail || '{}');
  const items = [
    {
      label: (
        <div
          className="flex flex-row"
          onClick={() => {
            navigate("/user-profile");
          }}
        >
          <FiUser className="hover:cursor-pointer" size={25} />
          <p className="pl-3 font-semibold">Profile</p>
        </div>
      ),
      key: "1",
    },

    {
      label: (
        <div
          className="flex flex-row"
          onClick={() => {
            navigate("/change-password");
          }}
        >
          <AiOutlineKey className="hover:cursor-pointer" size={25} />
          <p className="pl-3 font-semibold"> Change Password</p>
        </div>
      ),
      key: "3",
    },
    {
      label: (
        <div
          className="flex flex-row"
          onClick={() => {
            localStorage.clear();
            navigate("/");
          }}
        >
          <AiOutlineLogout className="hover:cursor-pointer" size={25} />
          <p className="pl-3 font-semibold">Logout</p>
        </div>
      ),
      key: "4",
    }
  ];

  let url_str = document.URL;
  let url = new URL(url_str);

  return (
    <Layout
      hasSider
      style={{
        height: "100vh",
      }}
    >
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="my-4 flex items-center justify-center">
          {" "}
          <img src="/assets/layout-image.svg" alt="layout-image" className="layout-image" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultValue={url.pathname}
          selectedKeys={[url.pathname]}
          items={[
            {
              key: "/dashboard",
              icon: <AiFillHome />,
              onClick: () => {
                navigate("/dashboard");
              },
              label: "Dashboard",
            },
            {
              key: "/admin",
              icon: <FiUser />,
              onClick: () => {
                navigate("/admin");
              },
              label: "Admins",
            },
            {
              key: "/user-profile",
              icon: <FiUser />,
              onClick: () => {
                navigate("/user-profile");
              },
              label: "Profile",
            },
            {
              key: "/client",
              icon: <AiOutlineUserAdd />,
              onClick: () => {
                navigate("/client");
              },
              label: "Client Management",
            },
            // {
            //   key: "/customer",
            //   icon: <AiOutlineUserAdd />,
            //   onClick: () => {
            //     navigate("/customer");
            //   },
            //   label: "customer Management",
            // },
            {
              key: "/",
              icon: <AiOutlineLogout />,
              onClick: () => {
                localStorage.clear();
                navigate("/");
              },
              label: "Logout",
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{ padding: 0, background: colorBgContainer }}
          className="flex justify-between items-center"
        >
          <Button
            type="text"
            icon={collapsed ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <div className="flex gap-3 items-center mr-4">
            {/* <BsFillBellFill className="md:w-[20px] md:h-[20px] rounded-full shadow-lg object-cover hover:cursor-pointer sm:w-[10px] sm:h-[10px]" /> */}
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              className="pr-3 mt-1"
            >
              <a>
                <Space>
                  {/* {!user?.image ? ( */}
                  {/* <span className="w-[30px] h-[30px] rounded-full bg-[#483ea3] text-white text-[22px] font-[500] flex justify-center items-center">
                    userName
                  </span> */}
                  {/* ) : ( */}
                  <Avatar
                    src={userDetail.profileImageUrl}
                    alt="user-profile-image"
                    className="w-[30px] h-[30px] rounded-full shadow-lg profile-image"
                  />
                  {/* )} */}
                  <div className="capitalize font-medium font-poppins leading-6	text-xl text-[#000000]">
                    {userDetail.fullName}
                  </div>
                  <AiOutlineDown />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Header>
        <div className="white-space">
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "85vh",
            background: colorBgContainer,
            overflowY: "scroll",
          }}
        >
          {children}
        </Content>
          <div className="break-line">
            <div className="footer">Powered by <img src="/assets/footer-image.svg" alt="footer-image" className="footer-image" /></div>
          </div>
          </div>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
