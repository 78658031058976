import { Button, Input, Typography, Image } from "antd";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import CustomLayout from "../common/CustomLayout";
import { useNotification } from "../common/Notification";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { post, put } from "../common/axios";
// ******* this component will update password by verifing user by OTP or something else and update password
const ChangePasswordForm = () => {

  const { handleNotifications } = useNotification();
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const initialValues = { oldPassword: "", password: "", confirmPassword: "" };


  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Old password is required")
      .min(6, "Old password must have at least 6 characters"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must have at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const handleSubmit = async (values:any, { setSubmitting ,resetForm}:any) => {
    try {
      // Call your API here
      //   await changePasswordApiCall(values);
      console.log(values)
      post("/admin/change-password", values).then((response) => {
        if (response)  {  const { data } = response;
        if (data && data.settings && data.settings.success) {
          resetForm();
          handleNotifications("success", data.settings.message);
        } else {
          handleNotifications("error", data.settings.message);
        }}
      })
    } catch (error) {
      console.log(error);
      // Handle error here
    }

    setSubmitting(false);
  };

  return (
    <CustomLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <div className="flex justify-center items-center">
            <div className=" md:w-5/12 lg:w-4/12 bg-white p-10 rounded-lg ">
              {/* <Image
              src="/assets/logo.svg"
              alt="logo"
              width={40}
              height={40}
              preview={false}
            />
   */}
              <Typography className="text-sky-950 font-semibold text-3xl">
                Change Password
              </Typography>

              <Form className="flex flex-col gap-4 mt-5">
                <div className="mb-5">
                  <label
                    htmlFor="oldPassword"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Old Password
                  </label>
                  <div className="input-group" style={{ position: "relative" }}>
                    <Field
                      name="oldPassword"
                      type={oldPasswordVisible ? "text" : "password"}
                      placeholder="Old Password"
                      className={`bg-gray-50 border ${
                        errors.oldPassword && touched.oldPassword
                          ? "border-red-500"
                          : "border-gray-300"
                      } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary translate-middle-y end-0 pe-3"
                      onClick={() =>
                        setOldPasswordVisible((prevState) => !prevState)
                      }
                      style={{
                        position: "absolute",
                        top: "11px",
                      }}
                    >
                      {oldPasswordVisible ? (
                        <AiFillEyeInvisible
                          size={16}
                          className="hover:cursor-pointer"
                        />
                      ) : (
                        <AiFillEye size={16} className="hover:cursor-pointer" />
                      )}
                    </button>
                  </div>
                  {errors.oldPassword && touched.oldPassword ? (
                    <div className="text-red-500">{errors.oldPassword}</div>
                  ) : null}
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    New Password
                  </label>
                  <div className="input-group" style={{ position: "relative" }}>
                    <Field
                      name="password"
                      type={newPasswordVisible ? "text" : "password"}
                      placeholder="New Password"
                      className={`bg-gray-50 border ${
                        errors.password && touched.password
                          ? "border-red-500"
                          : "border-gray-300"
                      } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary translate-middle-y end-0 pe-3"
                      onClick={() =>
                        setNewPasswordVisible((prevState) => !prevState)
                      }
                      style={{
                        position: "absolute",
                        top: "11px",
                      }}
                    >
                      {newPasswordVisible ? (
                        <AiFillEyeInvisible
                          size={16}
                          className="hover:cursor-pointer"
                        />
                      ) : (
                        <AiFillEye size={16} className="hover:cursor-pointer" />
                      )}
                    </button>
                  </div>
                  {errors.password && touched.password ? (
                    <div className="text-red-500">{errors.password}</div>
                  ) : null}
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="confirmPassword"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Confirm Password
                  </label>
                  <div className="input-group" style={{ position: "relative" }}>
                    <Field
                      name="confirmPassword"
                      type={confirmPasswordVisible ? "text" : "password"}
                      placeholder="Confirm Password"
                      className={`bg-gray-50 border ${
                        errors.confirmPassword && touched.confirmPassword
                          ? "border-red-500"
                          : "border-gray-300"
                      } text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary translate-middle-y end-0 pe-3"
                      onClick={() =>
                        setConfirmPasswordVisible((prevState) => !prevState)
                      }
                      style={{
                        position: "absolute",
                        top: "11px",
                      }}
                    >
                      {confirmPasswordVisible ? (
                        <AiFillEyeInvisible
                          size={16}
                          className="hover:cursor-pointer"
                        />
                      ) : (
                        <AiFillEye size={16} className="hover:cursor-pointer" />
                      )}
                    </button>
                  </div>
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <div className="text-red-500">{errors.confirmPassword}</div>
                  ) : null}
                </div>

                <Button type="primary" htmlType="submit" style={{ backgroundColor: "#1C5257" }}>
                Submit
              </Button>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </CustomLayout>
  );
};

export default ChangePasswordForm;
