import { Button, Input, Typography, Image } from "antd";
import { AiFillEye, AiFillEyeInvisible, AiOutlineUpload } from "react-icons/ai";
import { useRef, useState, useEffect } from "react";
import { useNotification } from "../common/Notification";
import { useNavigate } from "react-router-dom";
import CustomLayout from "../common/CustomLayout";
import { MdFileUpload } from "react-icons/md";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { post, put, get } from "../common/axios";
import axios from "axios";
// ******* this component will update password by verifing user by OTP or something else and update password
const ProfileForm = () => {
  const userDetail: any = JSON.parse(localStorage.getItem("userData") || "{}");
  const [uploadedImage, setUploadedImage] = useState(
    userDetail.profileImageUrl
  );
  const { handleNotifications } = useNotification();
  const [imageName, setImageName] = useState(
    userDetail.profileImageName || "Upload File"
  );
  const [initialValues, setInitialValues] = useState({
    firstName: userDetail.firstName,
    lastName: userDetail.lastName,
    email: userDetail.email,
    phoneNumber: userDetail.phoneNumber,
    status: userDetail.status === "Active" ? "Active" : "Inactive",
  });

  const handleFileUpload = async (event: any) => {
    const file = event.currentTarget.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/upload_file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (
        response &&
        response.data &&
        response.data.settings &&
        response.data.settings.success
      ) {
        const fileData = response.data.data;
        setImageName(fileData.name);
        setUploadedImage(fileData.url);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const getUserDetail = async (adminId: number) => {
    await get(`/admin/get-detail/${adminId}`)
      .then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.settings && data.settings.success) {
            const detail = data.data;
            console.log(data.data);
            const userData = {
              firstName: detail.firstName,
              lastName: detail.lastName,
              email: detail.email,
              phoneNumber: detail.phoneNumber,
              status: detail.status === "Active" ? "Active" : "Inactive",
              profileImageName: detail.profileImageName,
              profileImageUrl: detail.profileImageUrl,
              adminId: detail.adminId,
              fullName: detail.fullName,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            setInitialValues({
              firstName: detail.firstName,
              lastName: detail.lastName,
              email: detail.email,
              phoneNumber: detail.phoneNumber,
              status: detail.status === "Active" ? "Active" : "Inactive",
            });
            setUploadedImage(detail.profileImageUrl);
            setImageName(detail.profileImageName);
            handleNotifications("success", data.settings.message);
          } else {
            handleNotifications("error", `${data.settings.message}`);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // useEffect(() => {
  //   getUserDetail(userDetail.adminId)
  // }, []);

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    await put(`/admin/profile-update/${userDetail.adminId}`, {
      ...values,
      adminId: userDetail.adminId,
      profileImage: imageName,
    })
      .then(async (response) => {
        if (response) {
          const { data } = response;
          if (data && data.settings && data.settings.success) {
            await getUserDetail(userDetail.adminId);
            handleNotifications("success", data.settings.message);
          } else {
            handleNotifications("error", `${data.settings.message}`);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    return true;
  };

  return (
    <CustomLayout>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <div className="flex justify-center items-center">
          <div className=" md:w-5/12 lg:w-4/12 bg-white p-10 rounded-lg ">
            <Typography
              className="text-sky-950 font-semibold text-3xl"
              style={{ color: "#1C5257" }}
            >
              User Profile
            </Typography>

            <Form className="max-w-lg mx-auto w-full">
              <div className="mb-5">
                <label
                  htmlFor="firstName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-6"
                >
                  First Name
                </label>
                <Field
                  name="firstName"
                  placeholder="First Name"
                  className="bg-gray-50 border border-gray-300
                 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="lastName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Last Name
                </label>
                <Field
                  name="lastName"
                  placeholder="Last Name"
                  className="bg-gray-50 border border-gray-300
                 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <Field
                  readOnly
                  name="email"
                  placeholder="Email"
                  className="bg-gray-50 border border-gray-300
                text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>

              <div className="mb-5">
                <label
                  htmlFor="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Phone Number
                </label>
                <Field
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className="bg-gray-50 border border-gray-300
                 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>

              <div className="mb-5">
                <div className="relative flex items-center">
                  <Field
                    type="file"
                    id="fileUpload"
                    name="fileUpload"
                    onChange={(event: any) => {
                      handleFileUpload(event);
                    }}
                    className="sr-only"
                  />
                  <span
                    onClick={() => {
                      const fileUploadInput =
                        document.getElementById("fileUpload");
                      if (fileUploadInput) {
                        fileUploadInput.click(); // Simulate click on file input
                      }
                    }}
                    className="cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <MdFileUpload
                      className="mr-2"
                      style={{
                        fontSize: "24px",
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </span>
                  {uploadedImage && (
                    <div className="ml-3">
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                        style={{ height: "50px" }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex">
                <div className="flex items-center me-4">
                  <Field
                    type="radio"
                    id="inline-radio"
                    name="status"
                    value="Active"
                    className="outline-none w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="status"
                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Active
                  </label>
                </div>
                <div className="flex items-center me-4">
                  <Field
                    type="radio"
                    id="inline-2-radio"
                    name="status"
                    value="Inactive"
                    className="outline-none w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="status"
                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Inactive
                  </label>
                </div>
              </div>

              <Button
                className="mt-3 text-white"
                style={{ backgroundColor: "#1C5257", float: "right" }}
                htmlType="submit"
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </Formik>
    </CustomLayout>
  );
};

export default ProfileForm;
