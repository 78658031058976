import { addDays ,startOfMonth, endOfMonth, isBefore, endOfDay, min} from "date-fns";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { Button } from "antd";


const FilterComponent = ({ onDateSelect }: any) => {
  const [state, setState] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: min([new Date(), endOfMonth(new Date())]),
      key: 'selection',
    },
  ]);
  const handleOkClick = () => {
    console.log(state[0]);
    onDateSelect(state[0]);
  };

  const handleCancelClick = () => {
    onDateSelect(null);
  };
  const handleSelect = (item: any) => {
    if (item.selection.endDate > new Date()) {
      item.selection.endDate = new Date();
    }
    setState([item.selection]);
  };

  return (
    <div className="back-white">
      <DateRangePicker
        onChange={handleSelect}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
      />
      <div className="button-wrapper">
        <Button
         onClick={handleCancelClick}
          className="mt-3 text-white"
          style={{ backgroundColor: "#1C5257", float: "right", marginRight: "8px"}}
        >
          Cancel
        </Button>
        <Button
         onClick={handleOkClick}
          className="mt-3 text-white"
          style={{ backgroundColor: "#1C5257", float: "right" }}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

export default FilterComponent;
