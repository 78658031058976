import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Input, Button, Table, Tag, Modal } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import CustomLayout from "../common/CustomLayout";
import AdminManagement from "./admin-management";
import { useNotification } from "../common/Notification";
import { post, remove } from "../common/axios";

type OnChange = NonNullable<TableProps<DataType>["onChange"]>;
type Filters = Parameters<OnChange>[1];
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface DataType {
  adminId: string;
  firstName:string;
  lastName:string;
  fullName: string;
  email: string;
  status: string;
  profileImageName: string;
  profileImageUrl: string;
  phoneNumber: string;
}

const Admin: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<DataType | null>(null);
  const { handleNotifications } = useNotification();
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const handleSearch = useCallback((event: any) => {
    setSearchText(event.target.value);
  }, []);

  const handleAction = useCallback(
    (action: string, endpoint: string, method: "post" | "remove") => {
      if (selectedRowKeys.length === 0) {
        handleNotifications("error", "Please select at least one row");
        return;
      }
      const request = method === "remove" ? remove : post;

      Modal.confirm({
        title: `Are you sure you want to ${action}?`,
        maskClosable: true,
        okButtonProps: {
          style: {
            backgroundColor: "#1C5257", // Change this to your desired color
            borderColor: "#1C5257", // Change this to your desired color
          },
        },
        onOk: () => {
            console.log(selectedRowKeys)
          request(endpoint, { ids: selectedRowKeys, status: action }).then(
            (response) => {
              if (response) {
                const { data } = response;
                if (data && data.settings && data.settings.success) {
                  handleNotifications("success", data.settings.message);
                  getList(pageNumber, pageSize);
                } else {
                  handleNotifications("error", data.settings.message);
                }
              }
            }
          );
          setSelectedRowKeys([]);
        },
        onCancel: () => {
          setSelectedRowKeys([]);
        },
      });
    },
    [selectedRowKeys, pageNumber, pageSize]
  );

  const handleChange: OnChange = (pagination: any, filters, sorter: any) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
    if (
      sortedInfo.columnKey !== sorter.columnKey ||
      sortedInfo.order !== sorter.order
    ) {
      return;
    }
    if (pagination) {
      const newPageNumber = pagination.current;
      const newPageSize = pagination.pageSize || 10;
      setPageNumber(newPageNumber);
      setPageSize(newPageSize);
      getList(newPageNumber, newPageSize);
    }
  };

  const onSelectChange = useCallback((selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  }, []);

  const filteredData = useMemo(() => {
    return searchText
      ? dataSource.filter((item) =>
          Object.values(item).some((s) =>
            s.toString().toLowerCase().includes(searchText.toLowerCase())
          )
        )
      : dataSource;
  }, [searchText, dataSource]);

  const columns: TableColumnsType<DataType> = useMemo(
    () => [
        {
            title: "Profile Image",
            dataIndex: "profileImageUrl",
            key: "profileImageUrl",
            width: "20%",
            render: (text: string) => (
              <img
                src={text}
                alt="Profile"
                style={{ width: '50px', height: '50px' }} // Adjust size as needed
              />
            ),
          },
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        filteredValue: filteredInfo.fullName || null,
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
        sortOrder: sortedInfo.columnKey === "fullName" ? sortedInfo.order : null,
        ellipsis: true,
        render: (text: string, record: DataType) => (
          <a
            onClick={() => {
              setSelectedClient(record); // set the selected customer
              setModalIsOpen(true); // open the modal
            }}
          >
            {text}
          </a>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortOrder: sortedInfo.columnKey === "email" ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: "PhoneNumber",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
        sortOrder: sortedInfo.columnKey === "phoneNumber" ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
        render: (status) => (
          <Tag color={status === "Active" ? "green" : "red"}>{status}</Tag>
        ),
      },
    ],
    [filteredInfo, sortedInfo]
  );

  useEffect(() => {
    if (!modalIsOpen) {
      getList(1, 10);
    }
  }, [modalIsOpen]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getList = (pageNumber: number, pageSize: number) => {
    post("/admin/admin-list", { page: pageNumber, limit: pageSize }).then(
      (response) => {
        if (response) {
          const { data } = response;
          if (data && data.settings && data.settings.success) {
            handleNotifications("success", data.settings.message);
            setDataSource(data.data);
            setTotal(data.settings.count);
          } else {
            handleNotifications("error", data.settings.message);
          }
        }
      }
    );
  };

  return (
    <CustomLayout>
      <div className="flex">
        <Input.Search
          placeholder="Search"
          onChange={handleSearch}
          style={{ width: 200, marginBottom: "1rem" }}
        />

        <Button
          type="primary"
          danger
          onClick={() =>
            handleAction("delete", "/admin/admin-delete", "remove")
          }
          className="ml-2"
        >
          Delete
        </Button>
        <Button
          type="primary"
          onClick={() =>
            handleAction("Active", "/admin/admin-change-status", "post")
          }
          className="ml-2 "
          style={{ backgroundColor: "#1C5257" }}
        >
          Active
        </Button>
        <Button
          type="primary"
          onClick={() =>
            handleAction("Inactive", "/admin/admin-change-status", "post")
          }
          className="ml-2 "
          style={{ backgroundColor: "#1C5257" }}
        >
          Inactive
        </Button>
        <div>
          <Button
            onClick={() => {
              setModalIsOpen(true);
              setSelectedClient(null);
            }}
            type="primary"
            className="ml-2"
            style={{ backgroundColor: "#1C5257" }}
          >
            Add
          </Button>
          {modalIsOpen && (
            <AdminManagement
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
              selectedClient={selectedClient}
            />
          )}
        </div>
      </div>
      {/* <Table
        rowKey={(record) => record.clientId}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={filteredData}
        pagination={{ total: dataSource && dataSource.length ?dataSource.length:10, pageSize: 10 }}
        onChange={handleChange}
      /> */}

      {dataSource && dataSource.length > 0 ? (
        <Table
          rowKey={(record) => record.adminId}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredData}
          pagination={{
            current: pageNumber,
            pageSize: pageSize,
            total: total,
          }}
          onChange={handleChange}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            fontSize: "1.5em",
            color: "#888",
          }}
        >
          <div
            style={{
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
            }}
          >
            Data Not Found
          </div>
        </div>
      )}
    </CustomLayout>
  );
};

export default Admin;
