import './App.css';
import  { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route ,useNavigate} from 'react-router-dom';
import { NotificationProvider } from './components/common/Notification';
import ResetPasswordForm from './components/auth/ResetPasswordForm';
import events from './components/common/history'; // replace './events' with the actual path to the events.js file
import Client from './components/Pages/client';
import ChangePasswordForm from './components/auth/ChangePasswordForm';
import ProfileForm from './components/auth/profile';
import Dashboard from './components/Pages/Dashboard';
import Admin from './components/Pages/admin-list';

const Login = lazy(() => import('./components/auth/Login'));
const ForgotPasswordForm = lazy(() => import('./components/auth/ForgotPasswordForm'));

function UnauthorizedHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleUnauthorized = () => {
      navigate('/');
    };

    events.on('unauthorized', handleUnauthorized);

    return () => {
      events.off('unauthorized', handleUnauthorized);
    };
  }, [navigate]);

  return null;
}
function App() {
  return (
    <NotificationProvider>
      {/* <div className="container-fluid w-100 p-0 h-100 fullscreen" >
        <div className="row  m-0 bg-white p-0 m-0 h-auto align-items-center">
          <div className="col-md-6 p-0">
            <img src="/assets/logo.svg" alt="login" className="img-fluid" />
          </div>
          <div className="col-md-6 p-0">
          </div>  </div>
      </div> */}
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
            <Router>
            <UnauthorizedHandler />
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                <Route path="/" element={<Login />} />
                  <Route path="/forget-password" element={<ForgotPasswordForm />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/reset-password" element={<ResetPasswordForm />} />
                  <Route path="/change-password" element={<ChangePasswordForm />} />
                  <Route path="/client" element={<Client />} />
                  <Route path="/user-profile" element={<ProfileForm />} />
                  <Route path="/admin" element={<Admin />} />
{/*                  
                  <Route path="/customer" element={<Customer />} />
                  <Route path="/customer-add" element={<CustomerManagement />} />
                  <Route path="/customer-update/:id" element={<CustomerManagement />} /> */}
                </Routes>
              </Suspense>
            </Router>
            {/* </MuiPickersUtilsProvider> */}
    </NotificationProvider>
  );
}

export default App;


