import { Image } from "antd";
import React from "react";
// import logo from '../../../public/assets/logo.svg'

// this is a common wrapper for (signup ,login ,reset-password and forgot-password)
const CommonFormWrapper = ({ children }:any) => {
  return (
    <div className="flex justify-center items-center h-[100vh] bg-gray-300/30">
      <div className="md:w-5/12 lg:w-4/12 bg-white p-10 rounded-lg shadow-lg">
        {/* change your logo image_src here */}
        <Image
          src="/assets/logo.svg"
          alt="logo"
          width={150}
          height={40}
          preview={false}
        />

        {/* any children wrapped with this component */}
        {children}
      </div>
    </div>
  );
};

export default CommonFormWrapper;
