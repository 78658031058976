import React, { useCallback, useEffect, useState } from "react";
import { BsPeopleFill, BsFillBellFill } from "react-icons/bs";
import CustomBarChart from "../common/CustomChart/CustomBarChart";
import CustomLineChart from "../common/CustomChart/CustomLineChart";
import CustomPieChart from "../common/CustomChart/CustomPieChart";
import CustomLayout from "../common/CustomLayout";
import { FiCalendar } from "react-icons/fi";
import FilterComponent from "../common/date-filter";
import Modal from "react-modal";
import { startOfMonth, endOfMonth, isToday, format, min } from "date-fns";
import { post, get } from "../common/axios";

interface Client {
  clientId: number;
  name: string;
}

interface DateRange {
  startDate: Date;
  endDate: Date;
}

const ICONS: any = {
  BsPeopleFill: <BsPeopleFill className="text-[25px] text-[#ffffff]" />,
  BsFillBellFill: <BsFillBellFill className="text-[25px] text-[#ffffff]" />,
};

const Dashboard = () => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [graphSummary, setGraphSummary] = useState([]);
  const [clientSummary, setClientSummary] = useState([]);
  const [apiCallSummary, setApiCallSummary] = useState([]);
  const [clientList, setClientList] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [apiCallProgress, setApiCallProgress] = useState([]);

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: startOfMonth(new Date()),
    endDate: min([new Date(), endOfMonth(new Date())]),
  });

  const handleDateChange = (range: any) => {
    if (range !== null) {
      setDateRange({
        startDate: range.startDate,
        endDate: range.endDate,
      });
    }
    setShowDatePicker(false);
  };

  useEffect(() => {
    const fetchClientData = async (clientId: any) => {
      try {
        const [pieChartData, clientApiCall] = await Promise.all([
          post("admin/apiCall-progress", {
            startDate: new Date(dateRange.startDate).getTime(),
            endDate: new Date(dateRange.endDate).getTime(),
            clientId: clientId || undefined,
          }),

          post("admin/get-client-apiCall", {
            startDate: new Date(dateRange.startDate).getTime(),
            endDate: new Date(dateRange.endDate).getTime(),
            clientId: clientId || undefined,
          }),
        ]);
        setApiCallSummary(clientApiCall.data.data);
        setApiCallProgress(pieChartData.data.data);
      } catch (error) {
        console.error(error);
      }
    };
      fetchClientData(selectedClient);
  }, [selectedClient, dateRange]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          graphSummaryResponse,
          clientSummaryResponse,
          clientData,
          pieChartData,
          clientApiCall,
        ] = await Promise.all([
          post("admin/get-graph-summary", {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }),
          post("admin/get-client-summary", {
            startDate: new Date(dateRange.startDate).getTime(),
            endDate: new Date(dateRange.endDate).getTime(),
          }),
          get("admin/client-autocomplete"),
          post("admin/apiCall-progress", {
            startDate: new Date(dateRange.startDate).getTime(),
            endDate: new Date(dateRange.endDate).getTime(),
          }),

          post("admin/get-client-apiCall", {
            startDate: new Date(dateRange.startDate).getTime(),
            endDate: new Date(dateRange.endDate).getTime(),
          }),
        ]);

        setGraphSummary(graphSummaryResponse.data.data);
        setClientSummary(clientSummaryResponse.data.data);
        if (clientData && clientData.data && clientData.data.data.length > 0) {
          setClientList(clientData.data.data);
        }
        setApiCallProgress(pieChartData.data.data);
        setApiCallSummary(clientApiCall.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [dateRange.startDate, dateRange.endDate]);

  return (
    <CustomLayout>
      <main className="bg-slate-50">
        <div className="dashboard-container">
          <p className="text-[#1C5257]">Dashboard</p>
          <div className="d-flex">
            <div className="filter-popup">
              <p>
                {format(dateRange.startDate, "d-MMM-yyyy")} -{" "}
                {format(dateRange.endDate, "d-MMM-yyyy")}
              </p>
              <FiCalendar onClick={() => setShowDatePicker(true)} />
              <Modal
                isOpen={showDatePicker}
                onRequestClose={() => setShowDatePicker(false)}
                contentLabel="Date Range Picker"
                className="date-filter"
              >
               <FilterComponent onDateSelect={handleDateChange} />
              </Modal>
            </div>
            <div>
              <select
                className="outline-none rounded-lg p-2  text-white"
                style={{ backgroundColor: "#1C5257" }}
                value={selectedClient || ""}
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                <option value="">All</option>
                {clientList && clientList.length > 0 ? (
                  clientList.map((client: any, index: any) => (
                    <option key={index} value={client.clientId}>
                      {client.name}
                    </option>
                  ))
                ) : (
                  <option>No clients found</option>
                )}
              </select>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-[20px] mt-[20px] mb-0">
          {graphSummary?.map((item: any, index: any) => {
            return (
              <div
                key={index}
                className={`flex flex-col justify-around p-[15px] rounded-[5px]`}
                style={{
                  backgroundColor: item.color,
                }}
              >
                <div className="flex items-center justify-between">
                  <h3 className="font-poppins text-[#ffffff] font-[500] text-[16px] leading-[39px]">
                    {item?.title}
                  </h3>
                  {ICONS[item.icon]}
                </div>
                <p className="font-poppins text-[#ffffff] font-[500] text-[16px] leading-[29px]">
                  {item.value}
                </p>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-2 gap-[20px] mt-[60px]">
          <div style={{ height: "350px" }}>
            <div className="flex justify-between items-center">
              <h1 className="font-poppins text-[#1C5257] font-[500] text-[16px] leading-[39px]">
                Total APIs Calls
              </h1>
            </div>
            {apiCallSummary && apiCallSummary.length > 0 ? (
              <CustomBarChart data={apiCallSummary} />
            ) : (
              <h1
                style={{
                  height: "350px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "25px",
                }}
              >
                Data not found
              </h1>
            )}
          </div>
          {!selectedClient ? (
            <div style={{ height: "350px" }}>
              <h1 className="font-poppins text-[#1C5257] font-[500] text-[16px] leading-[39px]">
                Registered Clients
              </h1>
              {clientSummary && clientSummary.length > 0 ? (
                <CustomBarChart data={clientSummary} />
              ) : (
                <h1
                  style={{
                    height: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                  }}
                >
                  Data not found
                </h1>
              )}
            </div>
          ) : (
            <div style={{ height: "350px" }}>
              <div className="flex justify-between items-center">
                <h1 className="font-poppins text-[#1C5257] font-[500] text-[16px] leading-[39px]">
                  API Calls
                </h1>
              </div>
              {apiCallProgress && apiCallProgress.length > 0 ? (
                <CustomPieChart pieChartData={apiCallProgress} />
              ) : (
                <h1
                  style={{
                    height: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                  }}
                >
                  Data not found
                </h1>
              )}
            </div>
          )}
        </div>
        {!selectedClient && (
          <div className="grid grid-cols-2 gap-[20px] mt-[60px]">
            <div style={{ height: "350px" }}>
              <div className="flex justify-between items-center">
                <h1 className="font-poppins text-[#1C5257] font-[500] text-[16px] leading-[39px]">
                  API Calls
                </h1>
              </div>
              {apiCallProgress && apiCallProgress.length > 0 ? (
                <CustomPieChart pieChartData={apiCallProgress} />
              ) : (
                <h1
                  style={{
                    height: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "25px",
                  }}
                >
                  Data not found
                </h1>
              )}
            </div>
          </div>
        )}
      </main>
    </CustomLayout>
  );
};

export default Dashboard;


