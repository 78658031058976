import React, { createContext, useContext } from "react";
import { notification } from "antd";

const NotificationContext = createContext();

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error();
  }
  return context;
};

export const NotificationProvider = ({ children }) => {
  const handleNotifications = (type, message, description, duration) => {
    notification[type]({
      message,
      key: message,
      description,
      duration,
    });
  };

  return (
    <NotificationContext.Provider value={{ handleNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
