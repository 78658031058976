import { Button, Input, Typography } from "antd";
import React, { useRef, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import CommonFormWrapper from "../common/CommonFormWrapper";
import { useNotification } from "../common/Notification";
import {post} from "../common/axios";
// ******* this component will update password by verifing user by OTP or something else and update password
const ResetPasswordForm = () => {
  const ref:any = useRef();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [formHasError, setFormHasError] = useState({
    otpCode: false,
    password: false,
    confirmPassword: false,
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { handleNotifications } = useNotification();
  // form submit handler
  const handleSubmit = async() => {
    const invalidInput =
      password.trim() === "" || confirmPassword.trim() === "";

    // check for validation of data
    if (Object.values(formHasError).includes(true) || invalidInput) {
      if (password.trim() === "") {
        setFormHasError((prev) => ({ ...prev, password: true }));
      }
      if (
        confirmPassword.trim() === "" ||
        confirmPassword.trim() !== password
      ) {
        setFormHasError((prev) => ({ ...prev, confirmPassword: true }));
      }
      return;
    }


    await post('/admin/reset-password', {
      otpCode,
      password
    })
  .then(response => {
    if (response)  {const {data} = response;
    console.log(data)
    if (data && data.settings && data.settings.success) {
      handleNotifications(
        "success",
        data.settings.message,
      );
      navigate("/");
    } else {
      handleNotifications(
        "error",
        `${data.settings.message}`,
        3
      );
    }}
  })
  .catch((error) => {
    console.error('Error:', error);
  });
  };

  return (
    <CommonFormWrapper>
      <Typography className="text-sky-950 font-semibold text-3xl">
        Reset password
      </Typography>

      <div className="flex flex-col gap-4 mt-5">

      <section className="flex flex-col gap-1">
          <Typography className="text-sky-950 font-semibold text-sm">
            OTP Code
          </Typography>
          <Input
            ref={ref}
            name="otpCode"
            value={otpCode}
            onChange={(e) => {
              setFormHasError((prev) => ({
                ...prev,
                otpCode: false,
              }));
              setOtpCode(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "enter") {
                handleSubmit();
              }
            }}
            onBlur={(e) => {
              if (e.target.value.trim() === "" || !/^\d+$/.test(e.target.value)) {
                setFormHasError((prev) => ({
                  ...prev,
                  otpCode: true,
                }));
              }
            }}
          />
          {formHasError.otpCode && (
            <p style={{ color: "red" }}>
              {otpCode.trim() === ""
                ? "OTP code is required !"
                : "OTP code should only contain alphabets"}
            </p>
          )}
        </section>

        <section className="flex flex-col gap-1">
          <Typography className="text-sky-950 font-semibold text-sm">
            New password
          </Typography>
          <Input.Password
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
            iconRender={(visible) =>
              visible ? (
                <AiFillEyeInvisible
                  size={16}
                  className="hover:cursor-pointer"
                />
              ) : (
                <AiFillEye size={16} className="hover:cursor-pointer" />
              )
            }
            name="password"
            value={password}
            onChange={(e) => {
              setFormHasError((prev) => ({ ...prev, password: false }));
              setPassword(e.target.value);
            }}
            onBlur={(e) => {
              if (e.target.value.trim() === "" || e.target.value.length <= 5) {
                setFormHasError((prev) => ({ ...prev, password: true }));
              }
            }}
          />
          {formHasError.password && (
            <p style={{ color: "red" }}>
              {password.trim() === ""
                ? "password is required !"
                : password.trim().length <= 5 &&
                  "password must have at least 6 characters !"}
            </p>
          )}
        </section>

        <section className="flex flex-col gap-1">
          <Typography className="text-sky-950 font-semibold text-sm">
            Confirm password
          </Typography>
          <Input.Password
            ref={ref}
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
            iconRender={(visible) =>
              visible ? (
                <AiFillEyeInvisible
                  size={16}
                  className="hover:cursor-pointer"
                />
              ) : (
                <AiFillEye size={16} className="hover:cursor-pointer" />
              )
            }
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => {
              setFormHasError((prev) => ({
                ...prev,
                confirmPassword: false,
              }));
              setConfirmPassword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "enter") {
                handleSubmit();
              }
            }}
            onBlur={(e) => {
              if (e.target.value.trim() === "" || e.target.value !== password) {
                setFormHasError((prev) => ({
                  ...prev,
                  confirmPassword: true,
                }));
              }
            }}
          />
          {formHasError.confirmPassword && (
            <p style={{ color: "red" }}>
              {confirmPassword.trim() === ""
                ? "confirm password is required !"
                : confirmPassword !== password &&
                  "Password and Confirm password must be same"}
            </p>
          )}
        </section>

        <Button
          onClick={handleSubmit}
          className="mt-3 text-white"
          style={{ backgroundColor: "#1C5257" }}
        >
          Submit
        </Button>
      </div>

      {/* redirect to login page */}
      <div className="flex justify-center gap-2 mt-3">
        <Typography className="text-sky-950/70">Back to</Typography>
        <Typography
          className="font-semibold hover:cursor-pointer"
          onClick={() => navigate("/")}
          style={{color: "#058A82"}}
        >
          Login
        </Typography>
      </div>
    </CommonFormWrapper>
  );
};

export default ResetPasswordForm;
